import React from "react";
import styled from "styled-components";

const Paragraph = styled.div`
  text-align: left;
  font-size: 13px;
  padding: 0 0 25px;
`;

const Explanation = () => {
  return (
    <Paragraph>
      Search for acquisitions from Planet, Sentinel-2A and Sentinel-2B. Draw
      area to access planet data.
    </Paragraph>
  );
};

export default Explanation;
