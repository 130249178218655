import React, { useEffect, useState, useRef, FC } from "react";
import "./Map.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import styled from "styled-components";
import Userdata from "./Userdata";
import { backendURL } from "./utils/environment";
import merge from "./utils/merge";

import { Feature, FeatureCollection, Geometry } from "geojson";
import { stringify, parse } from "wkt";

import Slide from "./Slide";
import HeatMapSlide from "./HeatMapSlide";
import envelope from "@turf/envelope";
import { distance, feature, Polygon } from "@turf/turf";
import HeatLegend from "./HeatLegend";
import CrystalSlide from "./CrystalMode";

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWRyaWFudHJlIiwiYSI6ImNpazJrZmg5aDAzNm92aGtwN3BsNXgwMGIifQ.e-mpPT33w6HNryn0Enjyxw";

const Map: FC = () => {
  const [mapper, setMapper] = useState<undefined | mapboxgl.Map>(undefined);
  const [draw, setDraw] = useState<undefined | MapboxDraw>(undefined);
  const [drawMode, setDrawMode] = useState<boolean>(false);
  const [polygon, setPolygon] = useState<Geometry | null>(null);
  const [area, setArea] = useState<string>("");

  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);

  const [dataSlider, setDataSlider] = useState<undefined | FeatureCollection>(
    undefined
  );

  const [planetData, setPlanetData] = useState<undefined | FeatureCollection>(
    undefined
  );
  const [usePlanet, setUsePlanet] = useState<boolean>(false);

  const [leftSlide, setLeftSlide] = useState<number>(30);
  const [rightSlide, setRightSlide] = useState<number>(60);
  const [minSlide, setMinSlide] = useState<number>(0);
  const [maxSlide, setMaxSlide] = useState<number>(100);
  const [startTimeSlider, setStartTimeSlider] = useState<number>(0);
  const [endTimeSlider, setEndTimeSlider] = useState<number>(100);
  const [aquiredData, setAquiredData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [moreLaterData, setmoreLaterData] = useState<boolean>(false);
  const [moreEarlierData, setmoreEarlierData] = useState<boolean>(false);

  const [crystalMode, setCrystalMode] = useState<boolean>(false);
  const [leftCrystal, setLeftCrystal] = useState<number>(30);
  const [rightCrystal, setRightCrystal] = useState<number>(60);
  const [minCrystal, setMinCrystal] = useState<number>(0);
  const [maxCrystal, setMaxCrystal] = useState<number>(100);
  const [crystal6DayData, setCrystal6DayData] = useState<
    undefined | FeatureCollection
  >(undefined);
  const [crystalData, setCrystalData] = useState<undefined | Feature[]>(
    undefined
  );
  const [dataHeatMap, setDataHeatMap] = useState<undefined | FeatureCollection>(
    undefined
  );
  const [totalHeatData, setTotalHeatData] = useState<
    Array<{ grid_id: number; date: string; count: number }> | undefined
  >(undefined);
  const [minHeatValue, setMinHeatValue] = useState<number>(0);
  const [maxHeatValue, setMaxHeatValue] = useState<number>(0);
  const [leftHeat, setLeftHeat] = useState<number>(30);
  const [rightHeat, setRightHeat] = useState<number>(60);
  const [minHeat, setMinHeat] = useState<number>(0);
  const [maxHeat, setMaxHeat] = useState<number>(100);
  const [aquiredHeat, setAquiredHeat] = useState<boolean>(false);
  const [startHeatSlider, setStartHeatSlider] = useState<number>(0);
  const [endHeatSlider, setEndHeatSlider] = useState<number>(100);
  const [moreLaterHeat, setMoreLaterHeat] = useState<boolean>(false);
  const [moreEarlierHeat, setMoreEarlierHeat] = useState<boolean>(false);
  const [gridSize, setGridSize] = useState<number | null>(null);

  const setFigureInMap = () => {
    if (area.split("(")[0] === "POLYGON ") {
      removeDrawnPolygon();
      setPolygon(parse(area));
      addDrawnPolygon(parse(area), mapper);
    }
  };

  const createQueryString = (
    startDate: string,
    endDate: string,
    polygon: Geometry,
    area: string
  ) => {
    let queryString = "?";
    if (startDate !== "") {
      queryString =
        queryString +
        "start_datetime=" +
        startDate +
        "&end_datetime=" +
        endDate +
        "&";
    }
    if (polygon) {
      if (queryString === "?") {
      }
      queryString = queryString + "aoi=" + stringify(polygon) + "&";
    } else if (area) {
      queryString = queryString + "aoi=" + area + "&";
    }
    if (queryString === "?") {
      //Empty request should show where the satelite is now
      var today = new Date();
      today.setHours(today.getHours() + 1);
      let endTime = new Date(today);
      endTime.setMonth(endTime.getMonth() + 1);
      queryString =
        queryString +
        "start_datetime=" +
        today.toISOString() +
        "&end_datetime=" +
        endTime.toISOString();
    }
    return queryString;
  };

  const sliderClick = async (startDateInput: string) => {
    const startDate =
      startDateInput === "" ? new Date() : new Date(startDateInput); //Empty string should give today
    startDate.setDate(startDate.getDate() - 16);
    let endTime = new Date(startDate);
    endTime.setMonth(endTime.getMonth() + 1);
    // startDate.setDate(startDate.getDate() - 12);
    // let endTime = new Date(startDate);
    // endTime.setDate(endTime.getDate() + 6);
    const queryString = createQueryString(
      startDate.toISOString(),
      endTime.toISOString(),
      polygon,
      area
    );
    setmoreLaterData(false);
    setmoreEarlierData(false);
    console.log("Query: ", queryString);
    setLoading(true);
    console.log("Full backendstring: ", `${backendURL()}/30days` + queryString);
    let response = await fetch(`${backendURL()}/30days` + queryString)
      .then((val) => {
        setErrorMsg(undefined);
        return val.json();
      })
      .catch((err) => {
        console.log("Error is: ", err.message);
        setErrorMsg("Could not retrive data because it " + err.message);
      });
    console.log("Response from backend, ", response);
    let startTimeSliderNumber = new Date(
      response["features"][0].properties.start_time
    ).getTime();
    let endTimeSliderNumber = new Date(
      response["features"].slice(-1)[0].properties.end_time
    ).getTime();
    if (usePlanet) {
      let planetResponse = await fetch(
        `${backendURL()}/planet_query` + queryString
      )
        .then((val) => {
          setErrorMsg(undefined);
          return val.json();
        })
        .catch((err) => {
          console.log("Error is: ", err.message);
          setErrorMsg("Could not retrive data because it " + err.message);
        });
      if (planetResponse.length !== 0) {
        const planetRaw = addStartTimeToPlanet(planetResponse);
        response = merge([response, planetRaw]);
        [startTimeSliderNumber, endTimeSliderNumber] = findStartAndEndTimes(
          planetResponse,
          startTimeSliderNumber,
          endTimeSliderNumber
        );
      } else {
        console.log("Empty response from planet ", planetResponse);
      }
    }
    setLoading(false);
    setEndTimeSlider(endTimeSliderNumber);
    setStartTimeSlider(startTimeSliderNumber);
    setMinSlide(startTimeSliderNumber);
    setMaxSlide(endTimeSliderNumber);
    const preparedData = prepareData(response);
    setDataSlider(preparedData);

    const startSlide = new Date(startDate);
    startSlide.setDate(startSlide.getDate() + 16);

    const endSlider = new Date(startSlide);
    endSlider.setDate(endSlider.getDate() + 1);
    setLeftSlide(startSlide.getTime());
    setRightSlide(endSlider.getTime());
    setAquiredData(true);
    setAquiredHeat(false);
  };
  const addStartTimeToPlanet = (response: FeatureCollection) => {
    response.features = response.features.map((d) => {
      d.properties.start_time = d.properties.acquired;
      d.properties.end_time = d.properties.acquired;
      return d;
    });
    return response;
  };

  const prepareData = (response: FeatureCollection) => {
    response.features = response.features.map((d) => {
      d.properties.fullTime = new Date(d.properties.start_time).getTime();
      return d;
    });
    return response;
  };

  const findStartAndEndTimes = (
    planetResponse: FeatureCollection,
    startTimeSliderNumber: number,
    endTimeSliderNumber: number
  ) => {
    const planetStartTime = new Date(
      planetResponse["features"][0].properties.start_time
    ).getTime();
    const planetStopTime = new Date(
      planetResponse["features"].slice(-1)[0].properties.end_time
    ).getTime();

    startTimeSliderNumber =
      startTimeSliderNumber < planetStartTime || planetStartTime !== null
        ? startTimeSliderNumber
        : planetStartTime;
    endTimeSliderNumber =
      endTimeSliderNumber > planetStopTime
        ? endTimeSliderNumber
        : planetStopTime;
    return [startTimeSliderNumber, endTimeSliderNumber];
  };

  const filterMonthDataByDate = (
    mapper: undefined | mapboxgl.Map,
    leftSlide: number,
    rightSlide: number
  ) => {
    const leftDate = new Date(leftSlide);
    const rightDate = new Date(rightSlide);
    const leftFilter = [[">=", "fullTime", leftDate.getTime()]];
    const rightFilter = [["<=", "fullTime", rightDate.getTime()]];
    mapper.setFilter("Month", ["all", ...leftFilter, ...rightFilter]);
    mapper.setFilter("OutlineMonth", ["all", ...leftFilter, ...rightFilter]);
    setMapper(mapper);
  };

  const add6CrystalDays = (features: Feature[]) => {
    const clonedFeatures = JSON.parse(JSON.stringify(features));
    const nextFeatures = clonedFeatures.map((d) => {
      let startSixDays = new Date(d.properties.start_time);
      let endSixDays = new Date(d.properties.end_time);
      startSixDays.setDate(startSixDays.getDate() + 6);
      endSixDays.setDate(endSixDays.getDate() + 6);
      d.properties.start_time = startSixDays.toISOString();
      d.properties.end_time = endSixDays.toISOString();
      d.properties.fullTime = startSixDays.getTime();
      return d;
    });
    return clonedFeatures;
  };
  const fillFuturedata = (filteredFeatures: Feature[], horizon: number) => {
    let next6Days = add6CrystalDays(filteredFeatures);
    filteredFeatures = [...filteredFeatures, ...next6Days];
    for (let i = 0; i < horizon; i++) {
      next6Days = add6CrystalDays(next6Days);
      filteredFeatures = [...filteredFeatures, ...next6Days];
    }
    return filteredFeatures;
  };
  useEffect(() => {
    if (dataSlider != undefined) {
      setAquiredData(false);
      const from = new Date();
      const laterDate = new Date(from.getTime() + 86400000 * 6);
      let filteredFeatures = dataSlider.features.filter(function (feature) {
        return (
          feature.properties.fullTime <= laterDate &&
          feature.properties.fullTime >= from.getTime()
        );
      });
      setCrystal6DayData(filteredFeatures); //Save original values
      const totalCrystalData = fillFuturedata(filteredFeatures, 15);
      const filteredCollection = {
        type: "FeatureCollection",
        features: totalCrystalData,
      };

      setCrystalData(filteredCollection);
    }
  }, [crystalMode, setCrystalMode]);

  useEffect(() => {
    if (crystalData != undefined) {
      setMinCrystal(crystalData.features[0].properties.fullTime);
      setMaxCrystal(crystalData.features.slice(-1)[0].properties.fullTime);
      setLeftCrystal(crystalData.features[0].properties.fullTime);
      setRightCrystal(
        crystalData.features[0].properties.fullTime + 172800000 * 2
      );
    }
  }, [crystalData, setCrystalData]);

  const filterCrystalDataByDate = (
    leftCrystal: number,
    rightCrystal: number
  ) => {
    const leftDate = new Date(leftCrystal);
    const rightDate = new Date(rightCrystal);
    const leftFilter = [[">=", "fullTime", leftDate.getTime()]];
    const rightFilter = [["<=", "fullTime", rightDate.getTime()]];
    mapper.setFilter("Crystal", ["all", ...leftFilter, ...rightFilter]);
    mapper.setFilter("OutlineCrystal", ["all", ...leftFilter, ...rightFilter]);
    setMapper(mapper);
  };

  useEffect(() => {
    if (minSlide !== 0 && mapper.getLayer("Month")) {
      filterMonthDataByDate(mapper, leftSlide, rightSlide);

      if (leftSlide <= startTimeSlider + 172800000 && !loading) {
        //subtracting two days in seconds
        getEarlierDate();
      }
    }
  }, [leftSlide, setLeftSlide]);

  const getEarlierDate = async () => {
    let earlierDate = new Date(startTimeSlider);
    earlierDate.setMonth(earlierDate.getMonth() - 1);
    const queryString = createQueryString(
      earlierDate.toISOString(),
      new Date(startTimeSlider).toISOString(),
      polygon,
      area
    );
    setLoading(true);
    let response = await fetch(`${backendURL()}/30days` + queryString)
      .then((val) => {
        setErrorMsg(undefined);
        return val.json();
      })
      .catch((err) => {
        console.log("Error is: ", err.message);
        setErrorMsg("Could not retrive data because " + err.message);
      });
    let startTimeSliderNumber = new Date(
      response["features"][0].properties.start_time
    ).getTime();
    if (usePlanet) {
      let planetResponse = await fetch(
        `${backendURL()}/planet_query` + queryString
      )
        .then((val) => {
          setErrorMsg(undefined);
          return val.json();
        })
        .catch((err) => {
          console.log("Error is: ", err.message);
          setErrorMsg("Could not retrive data because " + err.message);
        });
      console.log("Getting more Planet Response: ", planetResponse);
      if (planetResponse.length !== 0) {
        const planetRaw = addStartTimeToPlanet(planetResponse);
        const planetStartTime = new Date(
          planetResponse["features"][0].properties.acquired
        ).getTime();
        startTimeSliderNumber =
          startTimeSliderNumber < planetStartTime
            ? startTimeSliderNumber
            : planetStartTime;

        response = merge([response, planetRaw]);
      } else {
        console.log("Empty response from planet ", planetResponse);
      }
    }
    setLoading(false);

    setStartTimeSlider(startTimeSliderNumber);
    setmoreEarlierData(true);

    const preparedData = prepareData(response);
    setDataSlider(merge([dataSlider, preparedData]));
  };

  useEffect(() => {
    if (minSlide !== 0 && mapper.getLayer("Month")) {
      filterMonthDataByDate(mapper, leftSlide, rightSlide);
      if (rightSlide >= endTimeSlider - 172800000 && !loading) {
        //Subtracting two days in seconds
        getLaterDate();
      }
    }
  }, [rightSlide, setRightSlide]);

  const handleMoreEarlierData = () => {
    const minSlideDate = new Date(minSlide);
    const minSLideMinusOneWeek = new Date(minSlide).setDate(
      minSlideDate.getDate() - 14
    );
    if (startTimeSlider < minSLideMinusOneWeek) {
      const maxSlideDate = new Date(maxSlide);
      const maxSLideMinusOneWeek = new Date(maxSlide).setDate(
        maxSlideDate.getDate() - 14
      );
      setMinSlide(minSLideMinusOneWeek);
      setMaxSlide(maxSLideMinusOneWeek);
      setmoreLaterData(true);
    } else {
      const maxSlidePlusRatio = maxSlide - (minSlide - startTimeSlider);
      setMaxSlide(maxSlidePlusRatio);
      setMinSlide(startTimeSlider);
      setmoreEarlierData(false);
      setmoreLaterData(true);
    }
  };

  const handleMoreLaterData = () => {
    const maxSlideDate = new Date(maxSlide);
    const maxSLidePlusOneWeek = new Date(maxSlide).setDate(
      maxSlideDate.getDate() + 14
    );
    if (endTimeSlider > maxSLidePlusOneWeek) {
      const minSlideDate = new Date(minSlide);
      const minSLidePlusOneWeek = new Date(minSlide).setDate(
        minSlideDate.getDate() + 14
      );
      setMaxSlide(maxSLidePlusOneWeek);
      setMinSlide(minSLidePlusOneWeek);
      setmoreEarlierData(true);
    } else {
      const minSLidePlusRatio = minSlide + (endTimeSlider - maxSlide);
      setMinSlide(minSLidePlusRatio);
      setMaxSlide(endTimeSlider);
      setmoreEarlierData(true);
      setmoreLaterData(false);
    }
  };

  const getLaterDate = async () => {
    let laterDate = new Date(endTimeSlider);
    laterDate.setMonth(laterDate.getMonth() + 1);
    let start = new Date(endTimeSlider);
    let offset = new Date().getTimezoneOffset();
    laterDate.setMinutes(laterDate.getMinutes() - offset);
    start.setMinutes(start.getMinutes() - offset);
    const queryString = createQueryString(
      start.toISOString(),
      laterDate.toISOString(),
      polygon,
      area
    );
    setLoading(true);
    let response = await fetch(`${backendURL()}/30days` + queryString)
      .then((val) => {
        setErrorMsg(undefined);
        return val.json();
      })
      .catch((err) => {
        console.log("Error is: ", err.message);
        setErrorMsg("Could not retrive data because " + err.message);
      });
    setLoading(false);
    if (response.length !== 0) {
      let endTimeSliderNumber = new Date(
        response["features"].slice(-1)[0].properties.end_time
      ).getTime();

      if (usePlanet) {
        let planetResponse = await fetch(
          `${backendURL()}/planet_query` + queryString
        )
          .then((val) => {
            setErrorMsg(undefined);
            return val.json();
          })
          .catch((err) => {
            console.log("Error is: ", err.message);
            setErrorMsg("Could not retrive data because " + err.message);
          });
        if (planetResponse.length !== 0) {
          const planetRaw = addStartTimeToPlanet(planetResponse);
          const planetStartTime = new Date(
            planetResponse["features"].slice(-1)[0].properties.acquired
          ).getTime();
          endTimeSliderNumber =
            endTimeSliderNumber < planetStartTime
              ? endTimeSliderNumber
              : planetStartTime;

          response = merge([response, planetRaw]);
        } else {
          console.log("Empty response from planet ", planetResponse);
        }
      }
      setEndTimeSlider(endTimeSliderNumber);
      setmoreLaterData(true);
      const preparedData = prepareData(response);
      setDataSlider(merge([dataSlider, preparedData]));
    } else {
      console.log("No data from response ");
    }
  };

  useEffect(() => {
    if (dataSlider) {
      removeMapSource(mapper);
      addMonthMapLayers(dataSlider, mapper);
      filterMonthDataByDate(mapper, leftSlide, rightSlide);
    }
  }, [dataSlider, setDataSlider]);

  useEffect(() => {
    if (crystalData) {
      removeMapSource(mapper);
      addCrystalBallLayer(crystalData, mapper);
    }
  }, [crystalData, setCrystalData]);

  const addCrystalBallLayer = (
    data: undefined | FeatureCollection,
    mapper: undefined | mapboxgl.Map
  ) => {
    if (data && mapper && crystalMode) {
      mapper.addSource("Crystal", {
        type: "geojson",
        data: data,
      });

      mapper.addLayer({
        id: "Crystal",
        type: "fill",
        interactive: true,

        source: "Crystal",
        layout: {},
        paint: {
          "fill-color": "#DD8822",
          "fill-opacity": 0.2,
        },
      });
      mapper.addLayer({
        id: "OutlineCrystal",
        type: "line",
        interactive: true,

        source: "Crystal",
        layout: {},
        paint: {
          "line-color": "#AAAAAA",
          "line-width": 2,
        },
      });
      mapper.on("click", "Crystal", async (e) => {
        const properties = e.features[0].properties;
        const date = new Date(properties.start_time);
        const queryString =
          "ogc_fid=" +
          properties.ogc_fid +
          "&point_wkt=POINT(" +
          e.lngLat.lng +
          " " +
          e.lngLat.lat +
          ")";
        const popup = new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(`
                <div><strong>Loading... </strong> </div>
                `);

        popup.addTo(mapper);

        let response = await fetch(
          `${backendURL()}/time_at_position?` + queryString
        )
          .then((val) => {
            setErrorMsg(undefined);
            return val.text();
          })
          .catch((err) => {
            console.log("Error is: ", err.message);
            setErrorMsg("Could not retrive data because it " + err.message);
          });
        const popups = document.getElementsByClassName("mapboxgl-popup");
        if (popups.length) {
          popups[0].remove();
        }

        const dateString: string =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        const timeString: string =
          date.getHours() +
          ":" +
          date.getMinutes() +
          ":" +
          date.getSeconds() +
          "." +
          date.getMilliseconds();
        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            `
                    <div><strong>Start date: </strong>${dateString}</div>
                    <div><strong>Start time: </strong>${timeString}</div>
                    <div><strong>Satellite: </strong>${properties.satellite}</div>
                    <div><strong>Time at point: </strong>${response}</div>
                    `
          )
          .addTo(mapper);
      });
    }
  };

  const addMonthMapLayers = (
    data: undefined | FeatureCollection,
    mapper: undefined | mapboxgl.Map
  ) => {
    if (data && mapper) {
      console.log("Loading 30 days of data");
      mapper.addSource("Month", {
        type: "geojson",
        data: data,
      });

      mapper.addLayer({
        id: "Month",
        type: "fill",
        interactive: true,

        source: "Month",
        layout: {},
        paint: {
          "fill-color": "#CCFF00",
          "fill-opacity": 0.4,
        },
      });
      mapper.addLayer({
        id: "OutlineMonth",
        type: "line",
        interactive: true,

        source: "Month",
        layout: {},
        paint: {
          "line-color": [
            "case",
            [">=", ["get", "fullTime"], new Date().getTime()],
            "#00FF00",
            "#0000FF",
          ],
          "line-width": 2,
        },
      });

      mapper.on("click", "Month", async (e) => {
        const properties = e.features[0].properties;
        const date = new Date(properties.start_time);
        const dateString: string =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        const timeString: string =
          date.getHours() +
          ":" +
          date.getMinutes() +
          ":" +
          date.getSeconds() +
          "." +
          date.getMilliseconds();
        if (properties.acquired === undefined) {
          console.log("props: ", properties);
          const queryString =
            "ogc_fid=" +
            properties.ogc_fid +
            "&point_wkt=POINT(" +
            e.lngLat.lng +
            " " +
            e.lngLat.lat +
            ")";
          const popup = new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(`
                  <div><strong>Loading... </strong> </div>
                  `);

          popup.addTo(mapper);
          let response = await fetch(
            `${backendURL()}/time_at_position?` + queryString
          )
            .then((val) => {
              setErrorMsg(undefined);
              return val.text();
            })
            .catch((err) => {
              console.log("Error is: ", err.message);
              setErrorMsg("Could not retrive data because it " + err.message);
            });
          const popups = document.getElementsByClassName("mapboxgl-popup");
          if (popups.length) {
            popups[0].remove();
          }
          new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(
              `
                    <div><strong>Start date: </strong>${dateString}</div>
                    <div><strong>Start time: </strong>${timeString}</div>
                    <div><strong>Satellite: </strong>${properties.satellite}</div>
                    <div><strong>Time at point: </strong>${response}</div>
                    `
            )
            .addTo(mapper);
        } else {
          //Planet case
          new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(
              `
                  <div><strong>Start date: </strong>${dateString}</div>
                  <div><strong>Start time: </strong>${timeString}</div>
                  <div><strong>Satellite: </strong>${properties.satellite_id}</div>
                  <div><strong>Capture Id: </strong>${properties.id}</div>
                  `
            )
            .addTo(mapper);
        }
      });
    }
  };

  const addDrawnPolygon = (
    polygon: Polygon,
    mapper: undefined | mapboxgl.Map
  ) => {
    mapper.addSource("maine", {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: polygon,
      },
    });
    mapper.addLayer({
      id: "maine",
      type: "fill",
      source: "maine", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#0080ff", // blue color fill
        "fill-opacity": 0.5,
      },
    });
    mapper.addLayer({
      id: "outline",
      type: "line",
      source: "maine",
      layout: {},
      paint: {
        "line-color": "#000",
        "line-width": 3,
      },
    });
  };

  const removeDrawnPolygon = () => {
    if (mapper) {
      if (mapper.getLayer("maine")) {
        mapper.removeLayer("maine");
        mapper.removeLayer("outline");
        mapper.removeSource("maine");
      }
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current && !mapper) {
      let coords = {
        lat: 50.80842296992687,
        lng: 1.430589693908944,
      };
      const maps: mapboxgl.Map = new mapboxgl.Map({
        container: containerRef.current,
        center: coords,
        zoom: 2,
        style: "mapbox://styles/mapbox/satellite-v9",
      });
      maps.on("load", () => {
        maps.addControl(new mapboxgl.NavigationControl(), "bottom-right");
        setMapper(maps);
      });
      maps.dragRotate.disable();
    }
  }, [mapper]);

  useEffect(() => {
    if (drawMode === false) {
    } else if (draw === undefined && drawMode === true) {
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        defaultMode: "draw_polygon",
      });
      setDraw(draw);
    } else if (draw !== undefined) {
      mapper.addControl(draw);
    } else {
    }
  }, [drawMode, setDrawMode]);

  useEffect(() => {
    if (draw !== undefined && mapper !== undefined) {
      mapper.addControl(draw);
      mapper.on("draw.create", updateArea);
      setUsePlanet(true);
    }
  }, [draw, mapper, setMapper]);

  const updateArea = () => {
    //Used by the confirmationbutton in Userdata
    if (draw) {
      setPolygon(draw.getAll()["features"][0]["geometry"]);
    }
  };
  const handleRemove = () => {
    // Used by the Removebutton in Userdata
    draw.trash();
    mapper.removeControl(draw);
    setDrawMode(false);
    setPolygon(null);
  };

  const removeMapSource = (mapper: undefined | mapboxgl.Map) => {
    if (mapper) {
      if (mapper.getLayer("Month")) {
        mapper.removeLayer("Month");
        mapper.removeLayer("OutlineMonth");
        mapper.removeSource("Month");
      } else if (mapper.getLayer("Heat")) {
        mapper.removeLayer("Heat");
        mapper.removeSource("Heat");
      } else if (mapper.getLayer("Crystal")) {
        mapper.removeLayer("Crystal");
        mapper.removeLayer("OutlineCrystal");
        mapper.removeSource("Crystal");
      }
    }
  };

  const addHeatMapLayers = (
    data: undefined | FeatureCollection,
    minValue: number,
    maxValue: number,
    mapper: undefined | mapboxgl.Map
  ) => {
    if (data && mapper) {
      mapper.addSource("Heat", {
        type: "geojson",
        data: data,
      });
      mapper.addLayer({
        id: "Heat",
        type: "fill",
        interactive: true,

        source: "Heat",
        layout: {},
        paint: {
          "fill-color": [
            "interpolate",
            ["linear"],
            ["get", "total_count"],
            minValue,
            "#000",
            maxValue,
            "#CCFF00",
          ],
        },
      });
      mapper.on("click", "Heat", async (e) => {
        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            `
            <div>Grid ID: ${e.features[0].properties.grid_id}</div>
            <div>Amount of acq: ${e.features[0].properties.total_count}</div>
            `
          )
          .addTo(mapper);
      });
    }
  };
  const calculateGridSize = (area: number) => {
    const ratio = 2000 ** 2;
    const subArea = area / ratio;
    if (Math.sqrt(subArea) > 1) {
      return 1;
    }
    return Math.sqrt(subArea);
  };

  const handleHeatClick = async (startDateInput: string) => {
    removeMapSource(mapper);
    const startDate =
      startDateInput === "" ? new Date() : new Date(startDateInput); //Empty string should give today
    let endTime = new Date(startDate);
    startDate.setMonth(startDate.getMonth() - 5);
    endTime.setMonth(endTime.getMonth() + 1);

    const geometryFeature: Feature<Geometry> = feature(polygon);
    const envelopedFeature: Feature<Polygon> = envelope(geometryFeature);
    const envelopedLength = distance(
      envelopedFeature.geometry.coordinates[0][0],
      envelopedFeature.geometry.coordinates[0][1]
    );
    const envelopedWidth = distance(
      envelopedFeature.geometry.coordinates[0][1],
      envelopedFeature.geometry.coordinates[0][2]
    );
    const area = envelopedLength * envelopedWidth;
    const gridSizer = calculateGridSize(area);
    setGridSize(gridSizer);

    const querystring =
      "?" +
      "start_datetime=" +
      startDate.toISOString() +
      "&end_datetime=" +
      endTime.toISOString() +
      "&aoi=" +
      stringify(polygon) +
      "&grid_size=" +
      gridSizer;
    setLoading(true);
    let response = await fetch(`${backendURL()}/frequency` + querystring)
      .then((val) => {
        setErrorMsg(undefined);
        return val.json();
      })
      .catch((err) => {
        console.log("Error is: ", err.message);
        setErrorMsg("Could not retrive data because it " + err.message);
      });
    const gridResponse = await fetch(
      `${backendURL()}/frequency_grid` +
        "?aoi=" +
        stringify(polygon) +
        "&grid_size=" +
        gridSizer.toString()
    )
      .then((val) => {
        return val.json();
      })
      .catch((err) => {
        console.log("Error is: ", err.message);
        setErrorMsg("Could not retrive data because it " + err.message);
      });
    setLoading(false);

    const minDate = JSON.parse(response).reduce(
      (
        output: Date,
        newDate: { grid_id: number; date: string; count: number }
      ) => {
        return (output || new Date("2025-12-24")) < new Date(newDate.date)
          ? newDate
          : output;
      }
    );
    const minTime = new Date(minDate.date).getTime();
    const maxDate = JSON.parse(response).reduce(
      (
        output: Date,
        newDate: { grid_id: number; date: string; count: number }
      ) => {
        return (output || new Date(1)) > new Date(newDate.date)
          ? output
          : newDate;
      }
    );
    const maxTime = new Date(maxDate.date).getTime();
    const aggregated: Record<string, number> = JSON.parse(response)
      .filter(
        (d) =>
          new Date(d.date) < new Date(maxTime - 86400000 * 4) &&
          new Date(d.date) > new Date(minTime + 86400000 * 6)
      )
      .reduce(
        (output, d) => ({
          ...output,
          [d.grid_id]: (output[d.grid_id] || 0) + d.count,
        }),
        {}
      );

    const minValue = 0;
    const maxValue = Math.max(...Object.values(aggregated));
    gridResponse.features.forEach((feature) => {
      feature.properties.total_count =
        aggregated[String(feature.properties.grid_id)] || 0;
    });

    setStartHeatSlider(minTime);
    setEndHeatSlider(maxTime);
    setMinHeat(minTime);
    setMaxHeat(maxTime);
    setLeftHeat(minTime + 86400000 * 6);
    setRightHeat(maxTime - 86400000 * 4);
    setMinHeatValue(minValue);
    setMaxHeatValue(maxValue);
    setDataHeatMap(gridResponse);
    setTotalHeatData(JSON.parse(response));
    setAquiredHeat(true);
    setAquiredData(false);
  };

  useEffect(() => {
    if (dataHeatMap) {
      removeMapSource(mapper);
      addHeatMapLayers(dataHeatMap, minHeatValue, maxHeatValue, mapper);
    }
  }, [dataHeatMap]);

  useEffect(() => {
    if (minHeat !== 0 && mapper.getLayer("Heat")) {
      if (leftHeat <= startHeatSlider + 172800000 && !loading) {
        getMoreHeatData("earlier");
      }
    }
  }, [leftHeat, setLeftHeat]);

  useEffect(() => {
    if (minHeat !== 0 && mapper.getLayer("Heat")) {
      if (rightHeat > endHeatSlider - 172800000 && !loading) {
        //subtracting two days in seconds
        getMoreHeatData("later");
      }
    }
  }, [rightHeat, setRightHeat]);

  const getMaxValue = () => {
    const newAggregated: Record<string, number> = totalHeatData.reduce(
      (output, d) => ({
        ...output,
        [d.grid_id]: (output[d.grid_id] || 0) + d.count,
      }),
      {}
    );
    const MaxValue = Math.max(...Object.values(newAggregated));
    setMaxHeatValue(MaxValue);
  };

  const filterHeatData = () => {
    const newAggregated: Record<string, number> = totalHeatData
      .filter(
        (d) =>
          new Date(d.date) < new Date(rightHeat) &&
          new Date(d.date) > new Date(leftHeat)
      )
      .reduce(
        (output, d) => ({
          ...output,
          [d.grid_id]: (output[d.grid_id] || 0) + d.count,
        }),
        {}
      );
    const newDataHeatMap: FeatureCollection = {
      ...dataHeatMap,
      features: dataHeatMap.features.map((feature) => {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            total_count: newAggregated[String(feature.properties.grid_id)] || 0,
          },
        };
      }),
    };
    setDataHeatMap(newDataHeatMap);
  };

  const getMoreHeatData = async (dataType: string) => {
    if (dataType === "later") {
      var endDate = new Date(endHeatSlider);
      endDate.setMonth(endDate.getMonth() + 1);
      var startDate = new Date(endHeatSlider);
      startDate.setDate(startDate.getDate() + 1);
    } else if (dataType === "earlier") {
      var startDate = new Date(startHeatSlider);
      startDate.setMonth(startDate.getMonth() - 1);
      var endDate = new Date(startHeatSlider);
    } else {
      console.log("Error: Type of datafetch not specified in getMoreHeatData");
    }
    const querystring =
      "?" +
      "start_datetime=" +
      startDate.toISOString() +
      "&end_datetime=" +
      endDate.toISOString() +
      "&aoi=" +
      stringify(polygon) +
      "&grid_size=" +
      gridSize.toString();
    setLoading(true);
    let response = await fetch(`${backendURL()}/frequency` + querystring)
      .then((val) => {
        setErrorMsg(undefined);
        return val.json();
      })
      .catch((err) => {
        console.log("Error is: ", err.message);
        setErrorMsg("Could not retrive data because it " + err.message);
      });
    setLoading(false);
    if (response !== "[]") {
      if (dataType === "later") {
        const newMaxDate = JSON.parse(response).reduce(
          (
            output: Date,
            newDate: { grid_id: number; date: string; count: number }
          ) => {
            return (output || new Date(1)) > new Date(newDate.date)
              ? output
              : newDate;
          }
        );
        const newMaxTime = new Date(newMaxDate.date);
        setEndHeatSlider(newMaxTime.getTime());
        setMoreLaterHeat(true);
        setMaxHeat(newMaxTime.getTime());
      } else if (dataType === "earlier") {
        const minDate = JSON.parse(response).reduce(
          (
            output: Date,
            newDate: { grid_id: number; date: string; count: number }
          ) => {
            return (output || new Date("2025-12-24")) < new Date(newDate.date)
              ? newDate
              : output;
          }
        );
        const minTime = new Date(minDate.date).getTime();
        setStartHeatSlider(minTime);
        setMoreEarlierHeat(true);
        setMinHeat(minTime);
      }
      getMaxValue();
      const newResponseObject = totalHeatData.concat(JSON.parse(response));
      setTotalHeatData(newResponseObject);
      filterHeatData();
    } else {
      console.log("No data: ", response);
    }
  };

  return (
    <>
      <Userdata
        sliderClick={sliderClick}
        setDrawMode={setDrawMode}
        handleRemove={handleRemove}
        setArea={setArea}
        mapper={mapper}
        area={area}
        drawMode={drawMode}
        polygon={polygon}
        loading={loading}
        errorMsg={errorMsg}
        handleHeatClick={handleHeatClick}
        setAquiredData={setAquiredData}
        setFigureInMap={setFigureInMap}
        aquiredData={aquiredData}
        aquiredHeat={aquiredHeat}
        setCrystalMode={setCrystalMode}
        crystalMode={crystalMode}
      />
      {aquiredHeat && (
        <HeatLegend minHeatValue={minHeatValue} maxHeatValue={maxHeatValue} />
      )}
      {aquiredData && (
        <Slide
          setLeftSlide={setLeftSlide}
          setRightSlide={setRightSlide}
          leftSlide={leftSlide}
          rightSlide={rightSlide}
          minSlide={minSlide}
          maxSlide={maxSlide}
          sliderClick={sliderClick}
          loading={loading}
          handleMoreLaterData={handleMoreLaterData}
          moreLaterData={moreLaterData}
          moreEarlierData={moreEarlierData}
          handleMoreEarlierData={handleMoreEarlierData}
        />
      )}
      {crystalMode && (
        <CrystalSlide
          setLeftCrystal={setLeftCrystal}
          setRightCrystal={setRightCrystal}
          filterCrystalDataByDate={filterCrystalDataByDate}
          leftCrystal={leftCrystal}
          rightCrystal={rightCrystal}
          minCrystal={minCrystal}
          maxCrystal={maxCrystal}
        />
      )}
      {aquiredHeat && (
        <HeatMapSlide
          setLeftHeat={setLeftHeat}
          setRightHeat={setRightHeat}
          leftHeat={leftHeat}
          rightHeat={rightHeat}
          minHeat={minHeat}
          maxHeat={maxHeat}
          filterHeatData={filterHeatData}
          moreLaterHeat={moreLaterHeat}
          moreEarlierHeat={moreEarlierHeat}
        />
      )}
      <Wrapper ref={containerRef} />
    </>
  );
};

export default Map;
