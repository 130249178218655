import React, { useState } from "react";
import "./App.css";
import Explanation from "./components/Explanation";
import Map from "./components/Map";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Map />
      </div>
    </QueryClientProvider>
  );
}
export default App;
