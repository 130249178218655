export const backendURL = () => {
  console.log("Backendurl: ", window.location.hostname);
  if (window.location.hostname === "localhost") {
    return "http://localhost:8000";
  } else if (
    window.location.hostname ===
    "test.react-skraper-frontend.vake-skrape.iterate.vake.hops.run"
  ) {
    return "https://test.api.vake-skrape.iterate.vake.hops.run";
  } else if (
    window.location.hostname ===
    "react-skraper-frontend.vake-skrape.app.iterate.no"
  ) {
    return "https://api.vake-skrape.app.iterate.no";
  } 
  else if (
    window.location.hostname ===
    "skrape-test.vake.ai"
  ) {
    return "https://skrape-api-test.vake.ai";
  } 
  else {
    console.log("backend url is wrong");
    console.log(window.location.hostname); 
    return "No correct hostname";
  }
};
